import { StyledText } from 'components/common'
import Features from 'contents/features'
import React from 'react'
import {
  FeaturesContainer,
  Wrapper,
} from './styled-components'
import { FeaturesSvg } from 'interfaces/FeaturesSvg'

// learning svgs
// import AdaptiveSvg from 'images/features/adaptive.svg'
// import AsyncSvg from 'images/features/async.svg'
// import CoachSvg from 'images/features/coach.svg'
// import FavouriteSvg from 'images/features/favourite.svg'
// import FlashcardsSvg from 'images/features/flashcards.svg'
// import LearnerSvg from 'images/features/learner.svg'
// import MessagingSvg from 'images/features/messaging.svg'
// import MultipleSvg from 'images/features/multiple.svg'
// import PreProSvg from 'images/features/pre-pro.svg'
// import PushNotificationsSvg from 'images/features/push-notifications.svg'
// import SkillTestVideoSvg from 'images/features/skill-test-video.svg'
// import QuizzesSvg from 'images/features/quizzes.svg'
// import SocialPeerSvg from 'images/features/social-peer.svg'
// import SortSvg from 'images/features/sort.svg'
// import SpotlightSvg from 'images/features/spotlight.svg'
// import TeachSvg from 'images/features/teach.svg'

// // teaching & coaching svgs
// import OnlineEducationSvg from 'images/features/online-education.svg'
// import DedicatedSvg from 'images/features/dedicated.svg'
// import ProgressSvg from 'images/features/progress.svg'
// import ScoreSvg from 'images/features/score.svg'
// import DiscussSvg from 'images/features/discuss.svg'
// import AnalyticsSvg from 'images/features/analytics.svg'
// import ModerationSvg from 'images/features/moderation.svg'

// // platform svgs
// import NativeSvg from 'images/features/native.svg'
// import SecuritySvg from 'images/features/security.svg'
// import LearningPathsSvg from 'images/features/learning-paths.svg'
// import FlashcardBuilderSvg from 'images/features/flashcard-builder.svg'
// import QuizzBuilderSvg from 'images/features/quizz-builder.svg'
// import CertificateSvg from 'images/features/certificate_1.svg'

// // courses svgs
// import ReadySvg from 'images/features/ready.svg'
// import EasyBuilding from 'images/features/easy-building.svg'
// import DynamicGroups from 'images/features/dynamic-groups.svg'

import PinkWaveTop from 'images/backgrounds/features-pink-top.svg'
import OrangeWaveTop from 'images/backgrounds/features-orange-top.svg'
import PinkWaveBottom from 'images/backgrounds/features-pink-bottom.svg'
import OrangeWaveBottom from 'images/backgrounds/features-orange-bottom.svg'
import BoxesSvg from 'images/svg/boxes.svg'
import PlaySvg from 'images/svg/play.svg'
import PillSvg from 'images/svg/features-pill.svg'
import { IFeaturesProps } from 'interfaces/PageInterfaces'

const FeaturesSection = ({
  learningTitle,
  learningArticles,
  teachingAndCoachingTitle,
  teachingAndCoachingArticles,
  platformTitle,
  platformArticles,
  coursesTitle,
  coursesArticles,
}: IFeaturesProps) => {
  return (
    <Wrapper>
      <div className="learning-top">
        <PinkWaveTop />
        <div className="boxes">
          <BoxesSvg />
        </div>
      </div>
      <div className={`section Learning`}>
        <StyledText
          textAlign="center"
          className="section-title"
          textColor={'#EB4B8A'}
        >
          {learningTitle}
        </StyledText>
        <FeaturesContainer variant={'Learning'}>
          {learningArticles.map(
            ({ icon, label, description }, index) => {
              return (
                <div key={index} className="feature">
                  <div className="icon-container">
                    <img
                      src={icon.file.url}
                      alt="feature-svg"
                    />
                  </div>
                  <h6>{label}</h6>
                  <p>{description}</p>
                </div>
              )
            },
          )}
        </FeaturesContainer>
      </div>
      <div className="reversed">
        <PinkWaveBottom />
        <div className="boxes">
          <PlaySvg />
        </div>
      </div>
      <div className={`section Teaching and coaching`}>
        <StyledText
          textAlign="center"
          className="section-title"
          textColor={'#06C68F'}
        >
          {teachingAndCoachingTitle}
        </StyledText>
        <FeaturesContainer
          variant={'Teaching and coaching'}
        >
          {teachingAndCoachingArticles.map(
            ({ icon, label, description }, index) => {
              return (
                <div key={index} className="feature">
                  <div className="icon-container">
                    <img
                      src={icon.file.url}
                      alt="feature-svg"
                    />
                  </div>
                  <h6>{label}</h6>
                  <p>{description}</p>
                </div>
              )
            },
          )}
        </FeaturesContainer>
      </div>
      <div className="learning-top">
        <OrangeWaveTop />
        <div className="pill">
          <PillSvg />
        </div>
      </div>
      <div className={`section Platform`}>
        <StyledText
          textAlign="center"
          className="section-title"
          textColor={'#110B50'}
        >
          {platformTitle}
        </StyledText>
        <FeaturesContainer variant={'Platform'}>
          {platformArticles.map(
            ({ icon, label, description }, index) => {
              return (
                <div key={index} className="feature">
                  <div className="icon-container">
                    <img
                      src={icon.file.url}
                      alt="feature-svg"
                    />
                  </div>
                  <h6>{label}</h6>
                  <p>{description}</p>
                </div>
              )
            },
          )}
        </FeaturesContainer>
      </div>
      <div className="orange-bottom">
        <OrangeWaveBottom />
      </div>
      <div className={`section Courses`}>
        <StyledText
          textAlign="center"
          className="section-title"
          textColor={'#F49140'}
        >
          {coursesTitle}
        </StyledText>
        <FeaturesContainer variant={'Courses'}>
          {coursesArticles.map(
            ({ icon, label, description }, index) => {
              return (
                <div key={index} className="feature">
                  <div className="icon-container">
                    <img
                      src={icon.file.url}
                      alt="feature-svg"
                    />
                  </div>
                  <h6>{label}</h6>
                  <p>{description}</p>
                </div>
              )
            },
          )}
        </FeaturesContainer>
      </div>
    </Wrapper>
  )
}

export default FeaturesSection

// export const FeatureSvg = ({
//   icon,
// }: {
//   icon: FeaturesSvg
// }) => {
//   switch (icon) {
//     case FeaturesSvg.adaptive:
//       return <AdaptiveSvg />
//     case FeaturesSvg.async:
//       return <AsyncSvg />
//     case FeaturesSvg.coach:
//       return <CoachSvg />
//     case FeaturesSvg.skillTestVideo:
//       return <SkillTestVideoSvg />
//     case FeaturesSvg.favourite:
//       return <FavouriteSvg />
//     case FeaturesSvg.flascards:
//       return <FlashcardsSvg />
//     case FeaturesSvg.learner:
//       return <LearnerSvg />
//     case FeaturesSvg.messaging:
//       return <MessagingSvg />
//     case FeaturesSvg.multiple:
//       return <MultipleSvg />
//     case FeaturesSvg.prePro:
//       return <PreProSvg />
//     case FeaturesSvg.pushNotifications:
//       return <PushNotificationsSvg />
//     case FeaturesSvg.quizzes:
//       return <QuizzesSvg />
//     case FeaturesSvg.socialPeer:
//       return <SocialPeerSvg />
//     case FeaturesSvg.sort:
//       return <SortSvg />
//     case FeaturesSvg.spotlight:
//       return <SpotlightSvg />
//     case FeaturesSvg.teach:
//       return <TeachSvg />
//     case FeaturesSvg.onlineEducation:
//       return <OnlineEducationSvg />
//     case FeaturesSvg.dedicated:
//       return <DedicatedSvg />
//     case FeaturesSvg.progress:
//       return <ProgressSvg />
//     case FeaturesSvg.score:
//       return <ScoreSvg />
//     case FeaturesSvg.discuss:
//       return <DiscussSvg />
//     case FeaturesSvg.analytics:
//       return <AnalyticsSvg />
//     case FeaturesSvg.native:
//       return <NativeSvg />
//     case FeaturesSvg.security:
//       return <SecuritySvg />
//     case FeaturesSvg.learningPaths:
//       return <LearningPathsSvg />
//     case FeaturesSvg.flashcardBuilder:
//       return <FlashcardBuilderSvg />
//     case FeaturesSvg.quizzBuilder:
//       return <QuizzBuilderSvg />
//     case FeaturesSvg.certificate:
//       return <CertificateSvg />
//     case FeaturesSvg.ready:
//       return <ReadySvg />
//     case FeaturesSvg.easyBuilding:
//       return <EasyBuilding />
//     case FeaturesSvg.dynamicGroups:
//       return <DynamicGroups />
//     case FeaturesSvg.moderation:
//       return <ModerationSvg />
//     default:
//       return <></>
//   }
// }
