import styled from 'styled-components'

export const Wrapper = styled.section`
  .learning-top {
    position: relative;

    .boxes {
      position: absolute;
      width: 200px;
      height: auto;
      top: 2%;
      left: 15%;

      @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
        left: 10%;
        top: -20px;
        width: 100px;
      }
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
      margin-top: 100px;
    }

    .pill {
      position: absolute;
      width: 250px;
      height: auto;
      top: 10%;
      left: 18%;

      @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
        width: 130px;
        left: 10%;
        top: 0%;

        svg {
          width: 100%;
        }
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
        width: 100px;
        left: 10%;
        top: -40px;
      }
    }
  }
  .orange-bottom {
    margin-top: -10%;
    position: relative;
  }
  .reversed {
    transform: rotate(180deg);
    margin-top: -20px;
    position: relative;

    .boxes {
      transform: rotate(-180deg);
      position: absolute;
      width: 250px;
      height: auto;
      bottom: 2%;
      right: 18%;

      @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
        right: 18%;
        bottom: -20px;
        width: 50px;

        svg {
          width: 100%;
        }
      }
    }
  }
  .Learning {
    background-color: rgba(251, 247, 253, 1);
    margin-top: -20px;
  }
  .Platform {
    background-color: rgba(255, 247, 251, 1);
    margin-top: -13%;
    position: relative;
    z-index: 1;
  }
  .section {
    padding: 20px 165px 120px 165px;
    @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
      padding: 20px 100px 80px 100px;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
      padding: 20px 50px 60px 50px;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
      padding: 80px 20px 0px 20px;
    }

    .section-title {
      font-size: 3.25rem;
      margin-bottom: 96px;

      @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
        font-size: 2rem;
        margin-bottom: 32px;
      }
    }
  }
  padding-bottom: 200px;
`

export const FeaturesContainer = styled.div<{ variant: string }>`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;

  .feature {
    width: ${({ variant }) =>
      variant === 'Teaching and coaching'
        ? 'calc(24% - 60px)'
        : 'calc(33% - 30px)'};
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
    margin-block: 20px;

    @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
      width: 47%;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
      width: 100%;
    }

    .icon-container {
      width: 80px;
      height: 100px;
    }

    h6 {
      font-size: 18px;
      font-family: 'Poppins-SemiBold';
      color: #110b50;
      line-height: 22px;
      max-width: 300px;
    }

    p {
      font-size: 16px;
      font-family: 'poppins';
      font-weight: 400;
      color: #110b50;
      line-height: 22px;
      max-width: 300px;
    }
  }
`
