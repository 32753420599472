import { StyledButton, StyledText } from 'components/common'
import { isValidElement } from 'helpers/defineHtmlAst'
import { TitleProps } from 'interfaces/PageInterfaces'
import React from 'react'
import { Wrapper } from './styled-components'

const HeadingSection = ({
  header,
  subHeader,
}: TitleProps) => {
  return (
    <Wrapper>
      <StyledText textAlign="center" fontSize="72px">
        {header}
      </StyledText>
      {isValidElement(subHeader) && (
        <StyledText
          variant="light"
          className="last-paragraph"
        >
          {subHeader}
        </StyledText>
      )}
      <StyledButton color="#06C68F">
        See it in action
      </StyledButton>
    </Wrapper>
  )
}

export default HeadingSection
