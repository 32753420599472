import { graphql } from 'gatsby'
import { IFeaturesPageProps } from 'interfaces/PageInterfaces'
import React from 'react'
import { Helmet } from 'react-helmet'
import {
  HeadingSection,
  FeaturesSection,
} from 'sections/Features'

const features = ({ data }: IFeaturesPageProps) => {
  const {
    bannerTexts,
    sectionTitles,
    learningArticles,
    teachingAndCoachingArticles,
    platformArticles,
    coursesArticles,
  } = data

  const learningArticlesArgs =
    learningArticles.nodes.reverse()
  const teachingAndCoachingArticlesArgs =
    teachingAndCoachingArticles.nodes.reverse()
  const platformArticlesArgs =
    platformArticles.nodes.reverse()
  const coursesArticlesArgs =
    coursesArticles.nodes.reverse()
  return (
    <>
      <Helmet title="Features" defer={false} />
      {/* <BannerNormalizer /> */}
      <HeadingSection {...bannerTexts} />
      <FeaturesSection
        {...sectionTitles}
        learningArticles={learningArticlesArgs}
        teachingAndCoachingArticles={
          teachingAndCoachingArticlesArgs
        }
        platformArticles={platformArticlesArgs}
        coursesArticles={coursesArticlesArgs}
      />
    </>
  )
}

export default features

export const featuresPageData = graphql`
  query {
    bannerTexts: contentfulFeaturesPage {
      header: block1BannerHeader
      subHeader: block1BannerSubHeader
    }
    sectionTitles: contentfulFeaturesPage {
      learningTitle: block2LearningTitle
      teachingAndCoachingTitle: block3TeachingAndCoachingTitle
      platformTitle: block4PlatformTitle
      coursesTitle: block5CoursesTitle
    }
    learningArticles: allContentfulFeaturesLearningArticle {
      nodes {
        icon {
          file {
            url
          }
        }
        label
        description
      }
    }
    teachingAndCoachingArticles: allContentfulFeaturesTeachingAndCoachingArticle {
      nodes {
        icon {
          file {
            url
          }
        }
        label
        description
      }
    }
    platformArticles: allContentfulFeaturesPlatformArticle {
      nodes {
        icon {
          file {
            url
          }
        }
        label
        description
      }
    }
    coursesArticles: allContentfulFeaturesCoursesArticle {
      nodes {
        icon {
          file {
            url
          }
        }
        label
        description
      }
    }
  }
`
