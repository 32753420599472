import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .title {
    color: rgba(17, 11, 80, 1);
  }

  .last-paragraph {
    font-size: 24px !important;
    text-align: center;
    margin-bottom: 40px;
  }

  .custom-button {
    justify-content: center;
  }

  padding: 12% 165px 0 165px !important;

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 160px 100px 0 100px !important;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 150px 50px 0 50px !important;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 140px 20px 0 20px !important;
    .custom-button {
      justify-content: center;
      width: 100%;
    }
  }
`
